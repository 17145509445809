import React from 'react'

import MediaQuery from 'react-responsive'
import mediaSize from '../../../constants/mediaSize'

import { Button } from '../../../Components'

import styles from './styles.module.scss'

const Questions = () => (
  <div className={styles.wrap}>
    <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
      <img
        alt="waveMobileBottom"
        src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/wave_mobile_footer_bottom.webp`}
        className={styles.waveMobileBottom}
      />
    </MediaQuery>
    <div className="base-layout">
      <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
        <img
          alt="bubble"
          src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/footer_mobile_bubble.png`}
          className={styles.bubbleMobile}
        />
      </MediaQuery>
      <div className={styles.content}>
        <div className={styles.textWrap}>
          <h2 className={styles.title}>
            { 'Остались вопросы?' }
          </h2>
          <p className={styles.text}>
            { 'Мы всегда на связи, просто позвоните или напишите нам.' }
          </p>
        </div>
        <div className={styles.buttonsWrap}>
          <Button
            type="yellow"
            wrapStyle={styles.callButton}
            to="https://lk.tome.ge/signup"
            isExternalLink
            isNotBlank
          >
            { 'Подключиться'.toUpperCase() }
          </Button>
          <Button
            wrapStyle={styles.writeButton}
            isExternalLink
            isNotBlank
            to="mailto:office@tome.ae"
          >
            { 'Задать вопрос'.toUpperCase() }
          </Button>
        </div>
      </div>
    </div>
  </div>
)

export default Questions
