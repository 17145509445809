import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MediaQuery from 'react-responsive'
import mediaSize from '../../constants/mediaSize'

import Button from '../Button'

import styles from './styles.module.scss'

class Connection extends React.PureComponent {
  constructor(props) {
    super(props)

    this.invite = localStorage.getItem('invite')
  }

  render() {
    const {
      isHome,
      isBusiness,
    } = this.props
    const {
      invite,
    } = this

    return (
      <div className={classNames(styles.wrap, isHome && styles.wrapHome)}>
        <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
          {isHome && (
            <img
              alt="waveMobileTop"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/wave_mobile_footer_top.webp`}
              className={styles.waveMobileTop}
            />
          )}
          <img
            alt="waveMobileBottom"
            src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/wave_mobile_footer_bottom.webp`}
            className={styles.waveMobileBottom}
          />
        </MediaQuery>
        <div className="base-layout">
          <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
            <img
              alt="bubble"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/footer_mobile_bubble.png`}
              className={styles.bubbleMobile}
            />
          </MediaQuery>
          <div className={styles.content}>
            <div className={styles.textWrap}>
              <h2 className={styles.title}>
                { 'Всё просто!' }
              </h2>
              <p className={styles.text}>
                { 'Подключение не отнимет у\u00A0вас много времени.' }
              </p>
            </div>
            <div className={styles.buttonsWrap}>
              <Button
                type="yellow"
                wrapStyle={styles.openButton}
                to={
                  isBusiness
                    ? `https://lk.tome.ge/signup?role=business${invite ? `&invite=${invite}` : ''}`
                    : `https://lk.tome.ge/signup${invite ? `?invite=${invite}` : ''}`
                }
                onClick={() => localStorage.removeItem('invite')}
                isExternalLink
                isNotBlank
              >
                { 'Подключиться'.toUpperCase() }
              </Button>
              <Button
                wrapStyle={styles.tryButton}
                isExternalLink
                isNotBlank
                to="mailto:office@tome.ae"
              >
                { 'Задать вопрос'.toUpperCase() }
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Connection.propTypes = {
  isHome: PropTypes.bool,
  isBusiness: PropTypes.bool,
}

export default Connection
