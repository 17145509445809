import React from 'react'
import { Helmet } from 'react-helmet'

export default () => (
  <div className="document">
    <Helmet>
      <title>Политика оплат :. tome</title>
      <meta property="og:title" content="Политика оплаты ООО «ТОМЕ»" />
      <meta property="og:url" content="https://tome.ge/#document-payment-policy" />
      <meta property="og:type" content="article" />
    </Helmet>
    <aside>
      <span>26 сентября 2024 г.</span>
    </aside>
    <h2>Политика оплаты</h2>
    <p>При оплате заказа банковской картой, обработка платежа (включая ввод номера карты) происходит на защищенной странице сервиса, которая прошла международную сертификацию. Это значит, что Ваши конфиденциальные данные (реквизиты карты, регистрационные данные и др.) не сохраняются на серверах сервиса, а их обработка полностью защищена и никто, в том числе наш сервис, не может получить банковские данные клиента.</p>
    <p>При работе с карточными данными применяется стандарт защиты информации, разработанный международными платёжными системами Visa и MasterCard — Payment Card Industry Data Security Standard (PCI DSS), что обеспечивает безопасную обработку реквизитов Банковской карты Держателя. Применяемая технология передачи данных гарантирует безопасность по сделкам с Банковскими картами путем использования протоколов Secure Sockets Layer (SSL), Verified by Visa, Secure Code, и закрытых банковских сетей, имеющих высшую степень защиты.</p>
    <p>При запросе возврата денежных средств при отказе от покупки, возврат производится исключительно на ту же банковскую карту, с которой была произведена оплата!</p>
  </div>
)
