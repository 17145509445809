import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import MediaQuery from 'react-responsive'
import mediaSize from '../../constants/mediaSize'

import styles from './styles.module.scss'

const currentYear = new Date().getFullYear()
const Footer = () => (
  <footer className={styles.wrap}>
    <div className="base-layout">
      <div className={styles.content}>
        <div className={styles.contentLeft}>
          <div className={styles.socialLinkWrap}>
            <a
              href="https://vk.com/tome_ru"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <span className={styles.socialLinkImageWrap}>
                <i className="i_vk" />
              </span>
              вконтакте
            </a>
            <a
              href="https://www.facebook.com/tome.public"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <span className={styles.socialLinkImageWrap}>
                <i className="i_facebook" />
              </span>
              facebook
            </a>
            <a
              href="https://www.instagram.com/tmpublic/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <span className={styles.socialLinkImageWrap}>
                <i className="i_instagram" />
              </span>
              instagram
            </a>
          </div>
          <span className={styles.copyright} title={`tome, ${currentYear}`}>
            <img
              alt=""
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/footer/hand.png`}
              className={styles.copyrightImage}
            />
            {`tome, ${currentYear}`}
          </span>
        </div>
        <MediaQuery minWidth={mediaSize.maxPhoneSize}>
          <div className={styles.contentRight}>
            <div className={styles.linksColumn}>
              <h5 className={styles.linksTitle}>
                { 'Продукты'.toUpperCase() }
              </h5>
              <Link to="/" className={styles.link}>
                Для самозанятых
              </Link>
              <Link to="/business" className={styles.link}>
                Для бизнеса
              </Link>
              <div className={styles.linkWrap}>
                <span className={styles.link}>
                  Цифровая карта
                </span>
                <Tooltip
                  overlayClassName="input-tooltip"
                  title="Скоро расскажем"
                >
                  <span className={styles.linkTooltip}>?</span>
                </Tooltip>
              </div>
            </div>
            <div className={styles.linksColumn}>
              <h5 className={styles.linksTitle}>
                { 'Почитать'.toUpperCase() }
              </h5>
              <a
                href="#document-privacy-policy"
                className={styles.link}
              >
                Privacy Policy
                <span className={styles.linkTooltip}>!</span>
              </a>
              <a
                href="https://tome.ru/public/AML_2024.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                AML Policy
              </a>
              <a
                href="#document-payment-policy"
                className={styles.link}
              >
                Payment Policy
              </a>
              {/*
              <a
                href="https://tome.ru/public/contract.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                Публичная оферта
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://tome.ru/public/safedeal_tinkoff.pdf"
                className={styles.link}
              >
                Публичная оферта
              </a>
              */}
            </div>
            <div className={styles.linksColumn}>
              <h5 className={styles.linksTitle}>
                { 'Помощь'.toUpperCase() }
              </h5>
              <a href="mailto:support@tome.ae" className={styles.link}>
                Support
              </a>
              <Link to="/contacts" className={styles.link}>
                Контакты
              </Link>
            </div>
          </div>
        </MediaQuery>
      </div>
    </div>
  </footer>
)

export default Footer
